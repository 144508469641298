import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { AppHeader, ThemeProvider } from '@mattilsynet/mt-ui'
import { Toast } from '@mattilsynet/mt-common'
import history from './history'
import store from './reducers/store'
import {
  Login,
  LoginCallback,
  Logout,
  LogoutCallback,
} from './containers/login'
import { useTypedSelector } from './common/custom-hooks'
import { uiActions } from './ducks/ui/actions'
import TopMenuElements from './containers/top-menu-elements'
import { themePicker } from './common/theme'
import { config$ } from './config'
import { map } from 'rxjs/operators'
import { uiSelectors } from './ducks/ui/selectors'
import { userSelectors } from './ducks/user/selectors'
import { IUiState } from './ducks/ui/types'
import { replaceState } from './common/common-router'
import { PageError } from './components/page-error'
import { PageLoading } from './components/page-loading'
import NoRoute from './routes/no-route'
import AccessDeniedRoute from './routes/access-denied'
import About from './routes/about'
import HomeRoute from './routes/home'
import ImageViewRoute from './routes/image-view'
import './app.css'
import { useScrollAnchor } from './common/useScrollAnchor'
import isBetween from 'dayjs/plugin/isBetween'
import locale from './common/nb-locale-dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import './common/elastic-logging'

dayjs.extend(isBetween)
dayjs.locale(locale)
dayjs.extend(customParseFormat)

export const PrivateRoute = ({
  component: Component,
  userManager,
  ...rest
}: any) => {
  const authUser = useTypedSelector(userSelectors.getAuthUser)
  const initializationStatus = useTypedSelector(
    uiSelectors.getInitializationStatus
  )
  const pathName = useTypedSelector((state) => state.router.location.pathname)
  const pathSearch: string = useTypedSelector(
    (state) => state.router.location.search
  )
  const path = pathName.concat(pathSearch)

  const dispatch = useDispatch()
  const errorAction = () => dispatch(uiActions.initialize())

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!authUser.accessToken) {
          dispatch(replaceState(`/login?path=${path}`))
        } else if (initializationStatus.error) {
          return (
            <PageError
              errorText="Kunne ikke laste inn Melding til lokalt Mattilsyn."
              errorAction={errorAction}
              errorActionText="Prøv å laste innhold på nytt"
            />
          )
        } else if (!initializationStatus.loaded) {
          return (
            <PageLoading loadingText="Laster Melding til lokalt Mattilsyn..." />
          )
        } else {
          return <Component userManager={userManager} {...props} />
        }
      }}
    />
  )
}

const RoutesView = () => {
  const environment = useTypedSelector(uiSelectors.getEnvironment)
  const theme = themePicker(environment)

  return (
    <ThemeProvider value={theme}>
      <AppHeader
        appName="Melding til lokalt Mattilsyn"
        env={environment}
        right={<TopMenuElements />}
      />
      <div className="end-of-life-message">
        <div>
          Hei, nå er ny løsning for bekymringsmelding lansert. 🎉 Meldinger fra
          slakteritilsynet sendes nå dit.
          <br />
          Nye meldinger vil ikke lenger komme i Melding til lokalt Mattilsyn.
          <br />
          Her er lenke til ny løsning:{' '}
          <a href="https://bekymringsmelding.landdyr.mattilsynet.io">
            https://bekymringsmelding.landdyr.mattilsynet.io
          </a>{' '}
          eller du finner den i Citrix.
          <br />
          <br />
          Lurer du på noe ta kontakt med oss i Team Landdyrtilsyn i TVR Tilsyn
          Bekymringsmelding (for landdyr)
          <br />
          Hilsen oss i Team Landdyrtilsyn 😇
        </div>
      </div>
      <ConnectedRouter history={history}>
        <Switch>
          <PrivateRoute
            exact
            path={[
              '/',
              '/mine-meldinger',
              '/melding/:meldingId?',
              '/meldinger/:produsentTilsynsobjektId',
            ]}
            component={HomeRoute}
          />
          <PrivateRoute
            exact
            path="/image-view/:id"
            component={ImageViewRoute}
          />
          <PrivateRoute exact path="/about" component={About} />

          <Route exact path="/access-denied" component={AccessDeniedRoute} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/login/callback" component={LoginCallback} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/logout/callback" component={LogoutCallback} />

          <PrivateRoute component={NoRoute} />
        </Switch>
      </ConnectedRouter>
      <Toast.FixedToastArea id={'common-toastarea'} />
    </ThemeProvider>
  )
}

config$.pipe(map(({ environment }) => environment)).subscribe((env) => {
  store.dispatch(uiActions.setEnvironment(env as IUiState['environment']))
})

const App = () => {
  // scrolls to anchor or default top if none, on location change
  useScrollAnchor(1000)
  return (
    <Provider store={store}>
      <RoutesView />
    </Provider>
  )
}

export default App
